import * as React from "react";
import { Image, StyleSheet, StyleProp, ViewStyle, View } from "react-native";
import * as Analytics from "expo-firebase-analytics";
import spacing from "../lib/spacing";
import { Video } from "../lib/api";
import { TextTiny, LinkTiny, LinkContainer } from "./TextHelpers";
import colors from "../lib/colors";
import { HeartIcon, Music } from "./Icons";
import useTypedSelector from "../redux/useTypedSelector";

type Props = {
  video: Video;
  size: "large" | "small" | "tiny";
  style?: StyleProp<ViewStyle>;
};

export const LARGE_THUMBNAIL_WIDTH = 320;
export const SMALL_THUMBNAIL_WIDTH = 210;
export const TINY_THUMBNAIL_WIDTH = 160;

export const styles = StyleSheet.create({
  tinyImage: {
    width: TINY_THUMBNAIL_WIDTH,
    height: TINY_THUMBNAIL_WIDTH / 1.78,
  },
  smallImage: {
    width: SMALL_THUMBNAIL_WIDTH,
    height: SMALL_THUMBNAIL_WIDTH / 1.78,
  },
  largeImage: {
    width: LARGE_THUMBNAIL_WIDTH,
    height: LARGE_THUMBNAIL_WIDTH / 1.78,
  },
  tinyContainer: {
    width: TINY_THUMBNAIL_WIDTH,
  },
  smallContainer: {
    width: SMALL_THUMBNAIL_WIDTH,
  },
  largeContainer: {
    width: LARGE_THUMBNAIL_WIDTH,
  },
});

function VideoThumbnail({ video, size, style }: Props) {
  const seenVideoIds = useTypedSelector((state) => state.user.seenVideoIds);
  const audioEnabled = useTypedSelector((state) => state.audio.enabled);
  const favoriteVideoIds = useTypedSelector(
    (state) => state.user.favoriteVideoIds
  );

  const seenCount = seenVideoIds[video.id] || 0;
  const seen = seenCount > 0;
  const favorited = favoriteVideoIds[video.id];

  const onClick = React.useCallback(() => {
    Analytics.logEvent("select_content", {
      content_type: "video",
      item_id: video.id,
      item_name: video.title,
      item_category: video.sectionId,
    });
  }, [video.id, video.sectionId, video.title]);

  return (
    <View
      style={[
        size === "large" && styles.largeContainer,
        size === "small" && styles.smallContainer,
        size === "tiny" && styles.tinyContainer,
        style,
      ]}
    >
      <LinkContainer
        navigate={{ name: "Video", params: { videoId: video.id } }}
        onPress={onClick}
      >
        <Image
          source={{ uri: video.thumbnail }}
          style={[
            size === "large" && styles.largeImage,
            size === "small" && styles.smallImage,
            size === "tiny" && styles.tinyImage,
            { borderRadius: spacing[8] },
            !!seen && { opacity: 0.5 },
          ]}
        />
      </LinkContainer>
      {!!seen && (
        <TextTiny
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: spacing[8],
            color: colors.blue,
          }}
          numberOfLines={1}
        >
          Watched
        </TextTiny>
      )}

      <View
        style={{
          position: "absolute",
          padding: spacing[8],
          flexDirection: "row",
        }}
      >
        {!!favorited && (
          <HeartIcon
            color={colors.blue}
            strokeColor={colors.blue}
            size={spacing[16]}
          />
        )}
        {!!(audioEnabled && video.audioURL) && (
          <Music fill={colors.grey} size={spacing[16]} />
        )}
      </View>

      <LinkTiny
        href={`/video/${video.id}`}
        navigate={{ name: "Video", params: { videoId: video.id } }}
        onPress={onClick}
        style={{ marginTop: spacing[8] }}
        numberOfLines={1}
      >
        {video.title}
      </LinkTiny>
    </View>
  );
}

export default VideoThumbnail;
