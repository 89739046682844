import * as React from "react";
import Svg, { Path, Polygon, G } from "react-native-svg";
import spacing from "../lib/spacing";

export function HamburgerMenuIcon({ color }: { color: string }) {
  return (
    <Svg height="25" viewBox="0 0 50 50" width="25" fill={color}>
      <Path d="m0 9v2h50v-2zm0 15v2h50v-2zm0 15v2h50v-2z" />
    </Svg>
  );
}

export function SearchIcon({ color }: { color: string }) {
  return (
    <Svg height="16" viewBox="0 0 30 30" width="16" fill={color}>
      <Path d="m13 3c-5.5110029 0-10 4.4889971-10 10 0 5.511003 4.4889971 10 10 10 2.396508 0 4.597385-.851014 6.322266-2.263672l5.970703 5.970703a1.0001 1.0001 0 1 0 1.414062-1.414062l-5.970703-5.970703c1.412658-1.724881 2.263672-3.925758 2.263672-6.322266 0-5.5110029-4.488997-10-10-10zm0 2c4.430123 0 8 3.5698774 8 8 0 4.430123-3.569877 8-8 8-4.4301226 0-8-3.569877-8-8 0-4.4301226 3.5698774-8 8-8z" />
    </Svg>
  );
}

export function HeartIcon({
  color,
  strokeColor,
  size = 24,
}: {
  color: string;
  strokeColor: string;
  size?: number;
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 497 470">
      <Path
        d="M140 20C73 20 20 74 20 140c0 135 136 170 228 303 88-132 229-173 229-303 0-66-54-120-120-120-48 0-90 28-109 69-19-41-60-69-108-69z"
        fill={color}
        stroke={strokeColor}
        strokeWidth="20"
      />
    </Svg>
  );
}

export function Logo({ height = spacing[48] }: { height?: number }) {
  const aspectRatio = 290 / 165;
  return (
    <Svg width={height * aspectRatio} height={height} viewBox="0 0 290 165">
      <Path d="m25 25h95v91h-95z" fill="#fff" />
      <Path
        clip-rule="evenodd"
        d="m111.8 93c0 1-.7 1.8-1.7 1.9h-.1-30.7c-1 0-1.8-.7-1.8-1.7v-.1-45.1c0-1 .7-1.8 1.7-1.9h.1 16.4c1 0 1.8.7 1.9 1.7v.1 18.3h12.5c1 0 1.8.7 1.9 1.7v.1 25zm-40 0c0 1-.7 1.8-1.7 1.9h-.1-30.9c-1 0-1.8-.7-1.9-1.7v-.1-45.1c0-1 .7-1.8 1.7-1.9h.1 16.4c1 0 1.8.7 1.8 1.7v.1 18.3h12.5c1 0 1.8.7 1.9 1.7v.1zm-71.8-93v143.8h38.8v21.2l25.2-21.2h79.3v-143.8z"
        fill="#38b6de"
        fill-rule="evenodd"
      />
      <Path
        d="m269.8 29.9-15.6 50.6-15.3-50.6h-9.1-11.4-55.2v18.7h22.8v67.8h20.7v-67.8h17.3l20.7 67.8h19l26.3-86.5z"
        fill="#fff"
      />
    </Svg>
  );
}

export function Play({
  size = 24,
  strokeColor,
}: {
  size?: number;
  strokeColor: string;
}) {
  return (
    <Svg width={size} height={size} fillRule="evenodd" clipRule="evenodd">
      <Path
        stroke={strokeColor}
        d="M23 12l-22 12v-24l22 12zm-21 10.315l18.912-10.315-18.912-10.315v20.63z"
      />
    </Svg>
  );
}

export function Pause({
  size = 24,
  strokeColor,
}: {
  size?: number;
  strokeColor: string;
}) {
  return (
    <Svg width={size} height={size} fillRule="evenodd" clipRule="evenodd">
      <Path
        stroke={strokeColor}
        d="M10 24h-6v-24h6v24zm10 0h-6v-24h6v24zm-11-23h-4v22h4v-22zm10 0h-4v22h4v-22z"
      />
    </Svg>
  );
}

export function Close({ size = 24, fill }: { size?: number; fill: string }) {
  return (
    <Svg height={size} viewBox="0 0 413.348 413.348" width={size}>
      <Path
        fill={fill}
        d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"
      />
    </Svg>
  );
}

export function RightChevron({
  size = 24,
  fill,
  rotation = "0",
}: {
  size?: number;
  fill: string;
  rotation?: string;
}) {
  return (
    <Svg height={size} width={size} viewBox="0 0 407.436 407.436">
      <G transform={`rotate(${rotation} 203.718, 203.718)`}>
        <Polygon
          fill={fill}
          points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "
        />
      </G>
    </Svg>
  );
}

export function Music({
  size = 24,
  fill,
}: {
  size?: number;
  fill: string;
  rotation?: string;
}) {
  return (
    <Svg height={size} width={size} viewBox="0 0 100 100" y="0px" x="0px">
      <Path
        d="m93.161 0.071c-33.501-1.114-60.941 11.243-60.941 11.243l-0.02 62.709c-3.411-1.354-7.559-1.675-11.772-0.651-9.083 2.207-15.031 9.82-13.285 17.007s10.524 11.225 19.606 9.019c8.564-2.081 14.338-8.969 13.507-15.772v-46.855s19.404-6.784 44.573-8.485v34.849c-3.374-1.292-7.443-1.585-11.579-0.58-9.083 2.206-15.031 9.819-13.285 17.007 1.745 7.187 10.523 11.224 19.606 9.018 7.931-1.927 13.471-7.977 13.587-14.264l0.003 0.004v-74.249z"
        fill={fill}
      />
    </Svg>
  );
}
