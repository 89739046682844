import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

type UserState = {
  id: string;
  seenVideoIds: Record<string, number>;
  favoriteVideoIds: Record<string, boolean>;
  thumbnailSize: "large" | "small" | "tiny";
};

let initialState: UserState = {
  id: uuidv4(),
  seenVideoIds: {},
  favoriteVideoIds: {},
  thumbnailSize: "tiny",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setThumbnailPreference(
      state,
      action: PayloadAction<{ size: "large" | "small" | "tiny" }>
    ) {
      state.thumbnailSize = action.payload.size;
    },
    viewVideoPage(state, action: PayloadAction<{ videoId: string }>) {
      const { videoId } = action.payload;

      const seenCount = state.seenVideoIds[action.payload.videoId] || 0;

      state.seenVideoIds = {
        ...state.seenVideoIds,
        [videoId]: seenCount + 1,
      };
    },
    clearViewHistory(state) {
      state.seenVideoIds = {};
    },
    favoriteVideo(
      state,
      action: PayloadAction<{ like: boolean; videoId: string }>
    ) {
      const { videoId, like } = action.payload;

      state.favoriteVideoIds = {
        ...state.favoriteVideoIds,
        [videoId]: like,
      };
    },
  },
});

export const {
  viewVideoPage,
  clearViewHistory,
  favoriteVideo,
  setThumbnailPreference,
} = userSlice.actions;

export default userSlice.reducer;
