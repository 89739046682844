import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Video, parseVideos, parseVideo } from "../lib/api";
import { searchVideosResponseUpdate } from "./search";
import {
  HomeResponse,
  SectionVideosResponse,
  VideoResponse,
} from "../lib/types";

type VideoState = {
  videoData: Record<string, Video>;
  featuredVideosBySection: Record<string, string[]>;
  videosBySection: Record<string, Record<string, boolean>>;
  featured: string[];
};

const initialState: VideoState = {
  videoData: {},
  featured: [],
  videosBySection: {},
  featuredVideosBySection: {},
};

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    videoResponseUpdate(state, action: PayloadAction<VideoResponse>) {
      const video = action.payload;
      const parsedVideo = parseVideo(video);

      state.videosBySection[video.sectionId] =
        state.videosBySection[video.sectionId] || {};
      state.videosBySection[video.sectionId][video.id] = true;

      state.videoData[video.id] = parsedVideo;
      state.videoData[video.id] = parsedVideo;
    },
    homeResponseUpdate(state, action: PayloadAction<HomeResponse>) {
      const videoData = { ...state.videoData };
      const featured: string[] = [];
      const featuredVideosBySection: Record<string, string[]> = {};
      const videosBySection: Record<string, Record<string, boolean>> = {
        ...state.videosBySection,
      };

      action.payload.videos.forEach((section) => {
        featuredVideosBySection[section.sectionId] = [];
        videosBySection[section.sectionId] =
          videosBySection[section.sectionId] || {};

        parseVideos(section.videos).forEach((video) => {
          videoData[video.id] = video;
          featuredVideosBySection[section.sectionId].push(video.id);
          videosBySection[section.sectionId][video.id] = true;
        });
      });

      parseVideos(action.payload.featuredVideos).forEach((video) => {
        videoData[video.id] = video;
        featured.push(video.id);
      });

      state.videoData = videoData;
      state.featured = featured;
      state.featuredVideosBySection = featuredVideosBySection;
      state.videosBySection = videosBySection;
    },
    sectionVideosResponseUpdate(
      state,
      action: PayloadAction<{ response: SectionVideosResponse; id: string }>
    ) {
      const videoData = { ...state.videoData };
      const videosBySection: Record<string, Record<string, boolean>> = {
        ...state.videosBySection,
      };

      parseVideos(action.payload.response.videoGroupings[0].videos).forEach(
        (video) => {
          videoData[video.id] = video;
          videosBySection[action.payload.id] =
            videosBySection[action.payload.id] || {};

          videosBySection[action.payload.id][video.id] = true;
        }
      );

      state.videoData = videoData;
      state.videosBySection = videosBySection;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(searchVideosResponseUpdate, (state, action) => {
      const videoData = { ...state.videoData };
      parseVideos(action.payload.response.videos).forEach((video) => {
        videoData[video.id] = video;
      });

      state.videoData = videoData;
    }),
});

export const {
  homeResponseUpdate,
  sectionVideosResponseUpdate,
  videoResponseUpdate,
} = videoSlice.actions;

export default videoSlice.reducer;
