import * as React from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../App";
import { RouteProp } from "@react-navigation/native";
import Container from "../components/Container";
import VideoGrid from "../components/VideoGrid";
import spacing from "../lib/spacing";
import useTypedSelector from "../redux/useTypedSelector";

type FeaturedScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Featured"
>;

type FeaturedScreenRouteProp = RouteProp<RootStackParamList, "Featured">;

type Props = {
  route: FeaturedScreenRouteProp;
  navigation: FeaturedScreenNavigationProp;
};

function FeaturedScreen({ route, navigation }: Props) {
  const featured = useTypedSelector((state) => state.video.featured);

  return (
    <Container style={{ padding: spacing[16] }}>
      <VideoGrid videoIds={featured} />
    </Container>
  );
}

export default FeaturedScreen;
