import * as React from "react";
import {
  StackHeaderLeftButtonProps,
  HeaderBackButton,
} from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";
import { NavigationProp } from "../App";

function BackButton({
  canGoBack,
  onPress,
  ...props
}: StackHeaderLeftButtonProps) {
  const navigation = useNavigation<NavigationProp>();

  const goHome = React.useCallback(() => {
    navigation.replace("Home");
  }, [navigation]);

  return <HeaderBackButton onPress={canGoBack ? onPress : goHome} {...props} />;
}

export default BackButton;
