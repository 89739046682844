import * as React from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../App";
import { RouteProp } from "@react-navigation/native";
import Container from "../components/Container";
import SearchBar from "../components/SearchBar";
import { useDeferredLoads } from "react-loads";
import { getSearch } from "../lib/api";
import { RefreshControl } from "react-native";
import VideoGrid from "../components/VideoGrid";
import { useDispatch } from "react-redux";
import { searchVideosResponseUpdate, searchVideosGet } from "../redux/search";
import useTypedSelector from "../redux/useTypedSelector";

type SearchScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Search"
>;

type SearchScreenRouteProp = RouteProp<RootStackParamList, "Search">;

type Props = {
  route: SearchScreenRouteProp;
  navigation: SearchScreenNavigationProp;
};

function SearchScreen({ route, navigation }: Props) {
  const [term, setTerm] = React.useState(route.params.term);
  const dispatch = useDispatch();
  const videoIds = useTypedSelector((state) => state.search.results);

  const { response, load, isPending } = useDeferredLoads("search", getSearch, {
    variables: [term],
  });

  React.useEffect(() => {
    if (response) {
      dispatch(searchVideosResponseUpdate({ response }));
    }
  }, [response]);

  React.useEffect(() => {
    if (term) {
      dispatch(searchVideosGet());

      load(term);
    }
  }, [term]);

  return (
    <Container
      refreshControl={
        <RefreshControl refreshing={isPending} title="Loading..." />
      }
    >
      <SearchBar
        onSearch={(term) => {
          setTerm(term);
        }}
        value={route.params.term}
      />
      <VideoGrid videoIds={videoIds} />
    </Container>
  );
}

export default SearchScreen;
