import * as React from "react";
import { NavigationContainer, LinkingOptions } from "@react-navigation/native";
import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import { AppearanceProvider } from "react-native-appearance";
import * as Analytics from "expo-firebase-analytics";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { StatusBar } from "react-native";
import HomeScreen from "./screens/HomeScreen";
import BBTVHeader from "./components/BBTVHeader";
import Container from "./components/Container";
import SectionScreen from "./screens/SectionScreen";
import VideoScreen from "./screens/VideoScreen";
import MenuScreen from "./screens/MenuScreen";
import FavoritesScreen from "./screens/FavoritesScreen";
import SearchScreen from "./screens/SearchScreen";
import FeaturedScreen from "./screens/FeaturedScreen";
import colors from "./lib/colors";
import BackButton from "./components/BackButton";
import PushNotificationHandler from "./lib/PushNotificationHandler";
import { store, persistor } from "./redux/store";
import FontLoader from "./lib/FontLoader";
import AudioPlayer from "./lib/AudioPlayer";

const matchAll = require("string.prototype.matchall");

matchAll.shim();

export type RootStackParamList = {
  Home: undefined;
  Section: {
    sectionId: string;
  };
  Video: {
    videoId: string;
  };
  Menu: undefined;
  Favorites: undefined;
  Featured: undefined;
  Search: {
    term?: string;
  };
};

const RootStack = createStackNavigator<RootStackParamList>();

export type NavigationProp = StackNavigationProp<RootStackParamList>;

const linking: LinkingOptions = {
  prefixes: [
    "https://tv.bahaiblog.net",
    "https://bahai-blog-tv.web.app",
    "http://localhost:19006",
    "com.halflightmedialtd.bbtv://",
  ],
  config: {
    screens: {
      Home: "/",
      Menu: "/menu",
      Section: "/category/:sectionId",
      Video: "/video/:videoId",
      Search: "/search/:term",
      Favorites: "/favorites",
      Featured: "/featured",
    },
  },
};

function App() {
  const routeNameRef = React.useRef<string>();
  const navigationRef = React.useRef<any>();

  return (
    <AppearanceProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <FontLoader>
            <PushNotificationHandler />
            <StatusBar backgroundColor={colors.background} />
            <NavigationContainer
              linking={linking}
              ref={navigationRef}
              onReady={() =>
                (routeNameRef.current = navigationRef.current.getCurrentRoute().name)
              }
              onStateChange={() => {
                const previousRouteName = routeNameRef.current;
                const currentRouteName = navigationRef.current.getCurrentRoute()
                  .name;

                if (previousRouteName !== currentRouteName) {
                  // The line below uses the expo-firebase-analytics tracker
                  // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
                  // Change this line to use another Mobile analytics SDK
                  Analytics.setCurrentScreen(currentRouteName);
                }

                // Save the current route name for later comparision
                routeNameRef.current = currentRouteName;
              }}
            >
              <RootStack.Navigator initialRouteName="Home">
                <RootStack.Screen
                  name="Home"
                  component={HomeScreen}
                  options={{
                    title: "Baha'i Blog TV — Browse Baha'i Inspired Videos",
                    headerTitle: () => <BBTVHeader />,
                    headerBackground: () => <Container />,
                  }}
                />
                <RootStack.Screen
                  name="Section"
                  component={SectionScreen}
                  options={{
                    headerLeft: (props) => <BackButton {...props} />,
                    headerTintColor: colors.white,
                    headerBackground: () => <Container />,
                  }}
                />
                <RootStack.Screen
                  name="Video"
                  component={VideoScreen}
                  options={{
                    headerLeft: (props) => <BackButton {...props} />,
                    headerTintColor: colors.white,
                    headerBackground: () => <Container />,
                  }}
                />
                <RootStack.Screen
                  name="Menu"
                  component={MenuScreen}
                  options={{
                    headerLeft: (props) => <BackButton {...props} />,
                    headerTintColor: colors.white,
                    headerBackground: () => <Container />,
                  }}
                />
                <RootStack.Screen
                  name="Search"
                  component={SearchScreen}
                  options={{
                    headerLeft: (props) => <BackButton {...props} />,
                    headerTintColor: colors.white,
                    headerBackground: () => <Container />,
                  }}
                />
                <RootStack.Screen
                  name="Favorites"
                  component={FavoritesScreen}
                  options={{
                    headerLeft: (props) => <BackButton {...props} />,
                    headerTintColor: colors.white,
                    headerBackground: () => <Container />,
                  }}
                />
                <RootStack.Screen
                  name="Featured"
                  component={FeaturedScreen}
                  options={{
                    headerLeft: (props) => <BackButton {...props} />,
                    headerTintColor: colors.white,
                    headerBackground: () => <Container />,
                  }}
                />
              </RootStack.Navigator>
            </NavigationContainer>
            <AudioPlayer />
          </FontLoader>
        </PersistGate>
      </Provider>
    </AppearanceProvider>
  );
}

export default App;
