import * as React from "react";
import { TouchableOpacity } from "react-native";
import { TextInputP } from "../components/TextHelpers";
import spacing from "../lib/spacing";
import { useForm, Controller } from "react-hook-form";
import { SearchIcon } from "./Icons";
import colors from "../lib/colors";
import * as Analytics from "expo-firebase-analytics";
import { Section } from "@expo/html-elements";

type Props = {
  onSearch: (term?: string) => void;
  value?: string;
};

interface FormData {
  term: string;
}

function SearchBar({ onSearch, value }: Props) {
  const { handleSubmit, register, control } = useForm<FormData>({
    defaultValues: {
      term: value,
    },
  });

  React.useEffect(() => {
    register("term");
  }, [register]);

  const onSubmit = (data: FormData) => {
    const search_term = data.term.toLowerCase();

    Analytics.logEvent("search", {
      search_term,
    });
    onSearch(search_term);
  };

  return (
    <Section
      style={{
        flex: 1,
        alignItems: "center",
        paddingTop: spacing[48],
        paddingBottom: spacing[48],
        paddingLeft: spacing[16],
        paddingRight: spacing[16],
        alignSelf: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <Controller
        control={control}
        render={({ onChange, onBlur, value }) => (
          <TextInputP
            style={{
              flex: 1,
              borderTopLeftRadius: spacing[8],
              borderBottomLeftRadius: spacing[8],
            }}
            placeholder="Search videos"
            placeholderTextColor={colors.grey}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            value={value}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
        name="term"
        defaultValue=""
      />
      <TouchableOpacity
        onPress={handleSubmit(onSubmit)}
        style={{
          backgroundColor: colors.white,
          height: spacing[32],
          paddingRight: spacing[8],
          paddingLeft: spacing[8],
          borderTopRightRadius: spacing[8],
          borderBottomRightRadius: spacing[8],
          alignItems: "center",
          alignSelf: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <SearchIcon color={colors.blue} />
      </TouchableOpacity>
    </Section>
  );
}

export default SearchBar;
