import * as React from "react";
import { Platform, View, TouchableOpacity, Image } from "react-native";
import { Audio, AVPlaybackStatus } from "expo-av";
import { useDispatch } from "react-redux";
import Slider from "@react-native-community/slider";
import { Pause, Play, Close, RightChevron } from "../components/Icons";
import colors from "./colors";
import spacing from "./spacing";
import useTypedSelector from "../redux/useTypedSelector";
import { clear, next, previous } from "../redux/audio";
import usePrevious from "./usePrevious";
import { TextTiny, TextP } from "../components/TextHelpers";
import VideoThumbnail from "../components/VideoThumbnail";

enum LoopingType {
  LOOPING_TYPE_ALL,
  LOOPING_TYPE_ONE,
}

export default function AudioPlayer() {
  const dispatch = useDispatch();

  const [
    playbackInstance,
    setPlaybackInstance,
  ] = React.useState<Audio.Sound | null>(null);

  const [hide, setHide] = React.useState(false);

  const [rate, setRate] = React.useState(1.0);
  const [shouldCorrectPitch, setShouldCorrectPitch] = React.useState(true);
  const [volume, setVolume] = React.useState(1.0);
  const [muted, setMuted] = React.useState(false);
  const [loopingType, setLoopingType] = React.useState<LoopingType>(
    LoopingType.LOOPING_TYPE_ALL
  );

  const index = useTypedSelector((state) => state.audio.index);

  const audioQueue = useTypedSelector((state) => state.audio.videoIds);
  const videoData = useTypedSelector((state) => state.video.videoData);

  const videoId = audioQueue[index];
  const video = videoData[videoId];

  const audioURL = video?.audioURL;

  const oldAudioURL = usePrevious(audioURL);

  const [
    playbackInstancePosition,
    setPlaybackInstancePosition,
  ] = React.useState<number | null>(null);
  const [
    playbackInstanceDuration,
    setPlaybackInstanceDuration,
  ] = React.useState<number | null | undefined>(null);
  const [shouldPlay, setShouldPlay] = React.useState(false);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isBuffering, setIsBuffering] = React.useState(false);

  const [isSeeking, setIsSeeking] = React.useState(false);
  const [seekPosition, setSeekPosition] = React.useState<number | null>(null);
  const wasSeeking = usePrevious(isSeeking);

  const onPlaybackStatusUpdate = React.useCallback(
    (status: AVPlaybackStatus) => {
      if (status.isLoaded) {
        setPlaybackInstancePosition(status.positionMillis);
        setPlaybackInstanceDuration(status.durationMillis);
        setIsPlaying(status.isPlaying);
        setIsBuffering(status.isBuffering);
        setRate(status.rate);
        setMuted(status.isMuted);
        setVolume(status.volume);
        setLoopingType(
          status.isLooping
            ? LoopingType.LOOPING_TYPE_ONE
            : LoopingType.LOOPING_TYPE_ALL
        );
        setShouldCorrectPitch(status.shouldCorrectPitch);

        if (status.didJustFinish && !status.isLooping) {
          dispatch(next());
        }
      } else if (status.error) {
        console.log(`FATAL PLAYER ERROR: ${status.error}`);
      }
    },
    [dispatch]
  );

  const loadNewPlaybackInstance = React.useCallback(
    async (uri: string) => {
      if (playbackInstance != null) {
        await playbackInstance.unloadAsync();
        setPlaybackInstance(null);
      }

      const source = { uri };

      const initialStatus = {
        shouldPlay,
        rate,
        shouldCorrectPitch,
        volume,
        isMuted: muted,
        isLooping: loopingType === LoopingType.LOOPING_TYPE_ONE,
      };

      const { sound, status } = await Audio.Sound.createAsync(
        source,
        initialStatus,
        onPlaybackStatusUpdate
      );

      setPlaybackInstance(sound);
    },
    [
      loopingType,
      muted,
      onPlaybackStatusUpdate,
      playbackInstance,
      rate,
      shouldCorrectPitch,
      shouldPlay,
      volume,
    ]
  );

  React.useEffect(() => {
    Audio.setAudioModeAsync({
      staysActiveInBackground: true,
      playsInSilentModeIOS: true,
    });

    return () => {
      if (playbackInstance != null) {
        playbackInstance.unloadAsync();
      }
    };
  }, [playbackInstance]);

  React.useEffect(() => {
    if (audioURL && audioURL !== oldAudioURL) {
      loadNewPlaybackInstance(audioURL);
    }
  }, [audioURL, loadNewPlaybackInstance, oldAudioURL]);

  React.useEffect(() => {
    if (playbackInstance) {
      if (shouldPlay) {
        playbackInstance.playAsync();
      } else {
        playbackInstance.pauseAsync();
      }
    }
  }, [shouldPlay, playbackInstance]);

  const getSeekSliderPosition = React.useCallback(() => {
    if (isSeeking) {
      return undefined;
    }

    if (
      playbackInstance != null &&
      playbackInstancePosition != null &&
      playbackInstanceDuration != null
    ) {
      return playbackInstancePosition / playbackInstanceDuration;
    }
    return 0;
  }, [
    isSeeking,
    playbackInstance,
    playbackInstanceDuration,
    playbackInstancePosition,
  ]);

  React.useEffect(() => {
    if (playbackInstance != null && wasSeeking && !isSeeking && seekPosition) {
      if (isPlaying) {
        playbackInstance.playFromPositionAsync(seekPosition);
      } else {
        playbackInstance.setPositionAsync(seekPosition);
      }
    }
  }, [isPlaying, isSeeking, playbackInstance, seekPosition, wasSeeking]);

  const getMMSSFromMillis = React.useCallback(
    (millis: number | null | undefined) => {
      if (!millis) {
        return "-";
      }

      const totalSeconds = millis / 1000;
      const seconds = Math.floor(totalSeconds % 60);
      const minutes = Math.floor(totalSeconds / 60);

      const padWithZero = (number: number) => {
        const string = number.toString();
        if (number < 10) {
          return `0${string}`;
        }
        return string;
      };
      return `${padWithZero(minutes)}:${padWithZero(seconds)}`;
    },
    []
  );

  const getTimestamp = React.useCallback(() => {
    if (
      playbackInstance != null &&
      playbackInstancePosition != null &&
      playbackInstanceDuration != null
    ) {
      return `${getMMSSFromMillis(
        playbackInstancePosition
      )} / ${getMMSSFromMillis(playbackInstanceDuration)}`;
    }
    return "";
  }, [
    getMMSSFromMillis,
    playbackInstance,
    playbackInstanceDuration,
    playbackInstancePosition,
  ]);

  const onSlidingStart = React.useCallback((value: number) => {
    setIsSeeking(true);
  }, []);

  const onSlidingComplete = React.useCallback(
    (value: number) => {
      setIsSeeking(false);

      if (
        playbackInstance != null &&
        playbackInstanceDuration !== null &&
        playbackInstanceDuration !== undefined
      ) {
        setSeekPosition(value * playbackInstanceDuration);
      }
    },
    [playbackInstance, playbackInstanceDuration]
  );

  if (video) {
    return (
      <View
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style={{
          width: "100%",
          backgroundColor: colors.black,
          opacity: 0.7,
          padding: spacing[16],
          position: Platform.OS === "web" ? "fixed" : "absolute",
          bottom: 0,
        }}
      >
        <View
          style={{
            flex: 1,
            alignSelf: "center",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setHide(!hide);
            }}
          >
            <View>
              <RightChevron fill={colors.blue} rotation={hide ? "270" : "90"} />
            </View>
          </TouchableOpacity>
        </View>
        {!hide && (
          <View style={{ flex: 1 }}>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Image
                source={{ uri: video.thumbnail }}
                style={{
                  width: spacing[32],
                  height: spacing[32],
                  marginRight: 8,
                }}
              />
              <View style={{ marginRight: spacing[8] }}>
                <TouchableOpacity
                  onPress={() => {
                    setShouldPlay(false);
                    dispatch(clear());
                  }}
                >
                  <Close fill={colors.blue} />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  width: spacing[24],
                  height: spacing[24],
                  marginRight: spacing[16],
                }}
              >
                {isPlaying ? (
                  <TouchableOpacity
                    onPress={() => {
                      setShouldPlay(false);
                    }}
                  >
                    <Pause strokeColor={colors.blue} />
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    onPress={() => {
                      setShouldPlay(true);
                    }}
                  >
                    <Play strokeColor={colors.blue} />
                  </TouchableOpacity>
                )}
              </View>
              <View
                style={{
                  flex: 1,
                  alignItems: "stretch",
                  justifyContent: "center",
                  height: spacing[32],
                }}
              >
                <Slider
                  onSlidingStart={onSlidingStart}
                  onSlidingComplete={onSlidingComplete}
                  value={getSeekSliderPosition()}
                />
              </View>
              <View
                style={{
                  height: spacing[32],
                  paddingLeft: spacing[8],
                  paddingRight: spacing[8],
                  justifyContent: "center",
                }}
              >
                <TextTiny>{getTimestamp()}</TextTiny>
              </View>
              <View
                style={{
                  marginLeft: spacing[8],
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    dispatch(previous());
                  }}
                >
                  <RightChevron fill={colors.blue} rotation="180" />
                </TouchableOpacity>
              </View>
              <View style={{ marginLeft: spacing[8] }}>
                <TouchableOpacity
                  onPress={() => {
                    dispatch(next());
                  }}
                >
                  <RightChevron fill={colors.blue} />
                </TouchableOpacity>
              </View>
            </View>
            <TextP
              style={{
                paddingTop: spacing[8],
                paddingBottom: spacing[8],
                alignSelf: "center",
              }}
              numberOfLines={1}
            >
              {video.title}
            </TextP>
          </View>
        )}
      </View>
    );
  }
  return null;
}
