import React from "react";
import spacing from "../lib/spacing";
import VideoThumbnail from "./VideoThumbnail";
import { Section } from "@expo/html-elements";
import { LinkP } from "./TextHelpers";
import colors from "../lib/colors";
import useTypedSelector from "../redux/useTypedSelector";
import { useDispatch } from "react-redux";
import { setThumbnailPreference } from "../redux/user";

type Props = {
  videoIds: string[];
};

function VideoGrid({ videoIds }: Props) {
  const videoData = useTypedSelector((state) => state.video.videoData);
  const thumbnailSize = useTypedSelector((state) => state.user.thumbnailSize);
  const dispatch = useDispatch();

  return (
    <>
      <LinkP
        style={{
          color: colors.blue,
          padding: spacing[16],
          textAlign: "right",
        }}
        onPress={() => {
          dispatch(
            setThumbnailPreference({
              size: thumbnailSize === "tiny" ? "large" : "tiny",
            })
          );
        }}
      >
        Change View
      </LinkP>

      <Section
        style={{
          flex: 1,
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        {videoIds.map((id) => (
          <VideoThumbnail
            key={id}
            style={{
              marginBottom: spacing[16],
              marginLeft: spacing[4],
              marginRight: spacing[4],
            }}
            size={thumbnailSize}
            video={videoData[id]}
          />
        ))}
      </Section>
    </>
  );
}

export default VideoGrid;
