import * as React from "react";
import { RefreshControl } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../App";
import { RouteProp } from "@react-navigation/native";
import Container from "../components/Container";
import { useLoads } from "react-loads";
import { getSectionVideos, ApiResourceKey, getSections } from "../lib/api";
import VideoGrid from "../components/VideoGrid";
import { useDispatch } from "react-redux";
import useTypedSelector from "../redux/useTypedSelector";
import { sectionVideosResponseUpdate } from "../redux/video";
import { getSectionsResponse } from "../redux/section";

type SectionScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Section"
>;

type SectionScreenRouteProp = RouteProp<RootStackParamList, "Section">;

type Props = {
  route: SectionScreenRouteProp;
  navigation: SectionScreenNavigationProp;
};

function SectionScreen({ route, navigation }: Props) {
  const dispatch = useDispatch();
  const sectionData = useTypedSelector((state) => state.section.sectionData);
  const videosBySection = useTypedSelector(
    (state) => state.video.videosBySection
  );

  const { response: sectionsResponse } = useLoads(
    ApiResourceKey.SECTIONS,
    getSections
  );

  const { response, isPending, load } = useLoads(
    ApiResourceKey.VIDEO_SECTION,
    getSectionVideos,
    {
      variables: [route.params.sectionId],
    }
  );

  React.useEffect(() => {
    if (response && sectionsResponse) {
      dispatch(
        sectionVideosResponseUpdate({ response, id: route.params.sectionId })
      );
      dispatch(getSectionsResponse(sectionsResponse));
    }
  }, [response, sectionsResponse]);

  const title = sectionData[route.params.sectionId]?.category;

  React.useEffect(() => {
    navigation.setOptions({ title });
  }, [title]);

  const videoIds = Object.keys(videosBySection[route.params.sectionId] || {});

  return (
    <Container
      refreshControl={
        <RefreshControl
          refreshing={isPending}
          onRefresh={load}
          title="Loading..."
        />
      }
    >
      <VideoGrid videoIds={videoIds} />
    </Container>
  );
}

export default SectionScreen;
