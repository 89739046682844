import * as React from "react";
import {
  StyleSheet,
  SafeAreaView,
  StyleProp,
  ViewStyle,
  RefreshControlProps,
  View,
} from "react-native";
import { useColorScheme } from "react-native-appearance";
import { ScrollView } from "react-native-gesture-handler";
import colors from "../lib/colors";

export const MAX_SCREEN_WIDTH = 1200;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  lightContainer: {
    backgroundColor: colors.background,
  },
  darkContainer: {
    backgroundColor: colors.background,
  },
});

function Container({
  children,
  style,
  refreshControl,
}: React.PropsWithChildren<{
  style?: StyleProp<ViewStyle>;
  refreshControl?: React.ReactElement<RefreshControlProps>;
}>) {
  let colorScheme = useColorScheme();

  const themeContainerStyle =
    colorScheme === "light" ? styles.lightContainer : styles.darkContainer;

  return (
    <SafeAreaView style={[{ flex: 1 }, themeContainerStyle]}>
      <View style={[styles.container, themeContainerStyle, style]}>
        <ScrollView
          style={{ flex: 1, maxWidth: MAX_SCREEN_WIDTH, width: "100%" }}
          refreshControl={refreshControl}
        >
          {children}
        </ScrollView>
      </View>
    </SafeAreaView>
  );
}

export default Container;
