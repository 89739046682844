import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchResponse } from "../lib/types";

type SearchState = {
  results: string[];
};

let initialState: SearchState = {
  results: [],
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    searchVideosResponseUpdate(
      state,
      action: PayloadAction<{ response: SearchResponse }>
    ) {
      state.results = action.payload.response.videos.map((video) => video.id);
    },
    searchVideosGet(state) {
      state.results = [];
    },
  },
});

export const {
  searchVideosResponseUpdate,
  searchVideosGet,
} = searchSlice.actions;

export default searchSlice.reducer;
