import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SectionResponse, SectionsResponse } from "../lib/types";
import { homeResponseUpdate } from "./video";

type SectionState = {
  sectionData: Record<string, SectionResponse>;
  sections: string[];
};

let initialState: SectionState = {
  sections: [],
  sectionData: {},
};

const sectionSlice = createSlice({
  name: "section",
  initialState,
  reducers: {
    getSectionsResponse(state, action: PayloadAction<SectionsResponse>) {
      const sectionData = action.payload.sections.reduce((prev, curr) => {
        prev[curr.id] = curr;
        return prev;
      }, state.sectionData);

      const sections = Object.keys(sectionData).sort(function (a, b) {
        return sectionData[a].orderIndex - sectionData[b].orderIndex;
      });

      state.sections = sections;
      state.sectionData = sectionData;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(homeResponseUpdate, (state, action) => {
      const sectionData = action.payload.sections.reduce((prev, curr) => {
        prev[curr.id] = curr;
        return prev;
      }, state.sectionData);

      const sections = Object.keys(sectionData).sort(function (a, b) {
        return sectionData[a].orderIndex - sectionData[b].orderIndex;
      });

      state.sections = sections;
      state.sectionData = sectionData;
    }),
});

export const { getSectionsResponse } = sectionSlice.actions;

export default sectionSlice.reducer;
