import * as React from "react";
import Carousel from "react-native-snap-carousel";
import VideoThumbnail, {
  LARGE_THUMBNAIL_WIDTH,
  SMALL_THUMBNAIL_WIDTH,
  styles,
} from "./VideoThumbnail";
import { Dimensions } from "react-native";
import { TextH2, LinkContainer } from "./TextHelpers";
import { useNavigation } from "@react-navigation/native";
import { NavigationProp } from "../App";
import { Video } from "../lib/api";
import { MAX_SCREEN_WIDTH } from "./Container";
import spacing from "../lib/spacing";
import useTypedSelector from "../redux/useTypedSelector";

type Props = {
  size: "small" | "large";
  videoIds: string[];
  itemAlignment?: "start" | "center" | "end";
  seeMoreOnClick?: () => void;
  limit?: number;
};

const screenWidth = Math.min(
  Math.round(Dimensions.get("window").width),
  MAX_SCREEN_WIDTH
);

interface SeeMoreLink {
  thumbnail: null;
  seeMoreOnClick: () => void;
}

function VideoCarousel({
  videoIds,
  size,
  itemAlignment,
  seeMoreOnClick,
  limit,
}: Props) {
  const videoData = useTypedSelector((state) => state.video.videoData);
  const videos = videoIds.map((id) => videoData[id]);

  let data: (Video | SeeMoreLink)[] = [];

  if (limit) {
    data = [...videos.slice(0, limit)] as (Video | SeeMoreLink)[];
  } else {
    data = [...videos] as (Video | SeeMoreLink)[];
  }

  if (limit) {
    if (videoIds.length > limit && seeMoreOnClick) {
      data.push({
        seeMoreOnClick,
        thumbnail: null,
      });
    }
  } else if (seeMoreOnClick) {
    data.push({
      seeMoreOnClick,
      thumbnail: null,
    });
  }

  const navigation = useNavigation<NavigationProp>();

  return (
    <Carousel
      data={data}
      sliderWidth={screenWidth}
      activeSlideAlignment={itemAlignment}
      itemWidth={
        size === "large" ? LARGE_THUMBNAIL_WIDTH : SMALL_THUMBNAIL_WIDTH
      }
      renderItem={({ item }: { item: Video | SeeMoreLink }) => {
        if (item.thumbnail === null) {
          return (
            <LinkContainer
              onPress={seeMoreOnClick}
              style={[
                size === "large" ? styles.largeImage : styles.smallImage,
                {
                  display: "flex",
                  justifyContent: "center", //Centered vertically
                  alignItems: "center", // Centered horizontally
                  flex: 1,
                },
              ]}
            >
              <TextH2>See More →</TextH2>
            </LinkContainer>
          );
        } else {
          return (
            <VideoThumbnail
              style={{ marginLeft: spacing[16] }}
              size={size}
              video={item}
            />
          );
        }
      }}
    />
  );
}

export default VideoCarousel;
