import * as React from "react";

import {
  TextStyle,
  StyleSheet,
  TextInput,
  TextInputProps,
  GestureResponderEvent,
  TextProps,
  Linking,
  TouchableOpacity,
  StyleProp,
} from "react-native";
import { H1, H2, P } from "@expo/html-elements";
import { useColorScheme } from "react-native-appearance";
import spacing from "../lib/spacing";
import colors from "../lib/colors";
import { TextProps as HTMLTextStyle } from "@expo/html-elements/build/primitives/Text";
import { RootStackParamList, NavigationProp } from "../App";
import { useNavigation, Link } from "@react-navigation/native";
import { ViewStyle } from "@expo/html-elements/build/primitives/View";

const textStyles = StyleSheet.create({
  h1: {
    fontSize: 40,
    fontWeight: "400",
    lineHeight: 46,
  },
  h2: {
    fontSize: 20,
    fontWeight: "300",
    lineHeight: 26,
  },
  p: {
    fontSize: spacing[16],
    fontWeight: "300",
    lineHeight: 24,
  },
  tiny: {
    fontSize: spacing[12],
    fontWeight: "300",
    lineHeight: 16,
  },
  dark: {
    color: colors.white,
  },
  light: {
    color: colors.white,
  },
  font: {
    fontFamily: "NewsCycle_400Regular",
    letterSpacing: 1,
    marginTop: 0,
    marginBottom: 0,
  },
});

function GenericText(
  Component: React.ComponentType<HTMLTextStyle>,
  textStyle: TextStyle
) {
  return ({
    children,
    style,
    ...props
  }: React.PropsWithChildren<HTMLTextStyle>) => {
    let colorScheme = useColorScheme();

    return (
      <Component
        style={[
          textStyles.font,
          textStyle,
          colorScheme === "light" ? textStyles.light : textStyles.dark,
          style,
        ]}
        {...props}
      >
        {children}
      </Component>
    );
  };
}

type LinkProps<
  RouteType extends keyof RootStackParamList,
  ParamsType extends RootStackParamList[RouteType]
> = React.PropsWithChildren<
  TextProps & {
    onPress?: () => void;
    href?: string;
    navigate?: { name: RouteType; params: ParamsType };
  }
>;

function GenericLink(textStyle: TextStyle) {
  return <
    RouteType extends keyof RootStackParamList,
    ParamsType extends RootStackParamList[RouteType]
  >({
    children,
    style,
    onPress,
    href,
    navigate,
    ...props
  }: LinkProps<RouteType, ParamsType>) => {
    let colorScheme = useColorScheme();
    const navigation = useNavigation<NavigationProp>();

    return (
      <Link
        to={href || "#"}
        onPress={(
          event:
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>
            | GestureResponderEvent
        ) => {
          event.preventDefault();

          if (!onPress && !navigate && href) {
            Linking.openURL(href);
          } else {
            if (onPress) {
              onPress();
            }

            if (navigate) {
              navigation.navigate(navigate);
            }
          }
        }}
        style={[
          textStyles.font,
          textStyle,
          colorScheme === "light" ? textStyles.light : textStyles.dark,
          style,
        ]}
        {...props}
      >
        {children}
      </Link>
    );
  };
}

function GenericHyperText<
  RouteType extends keyof RootStackParamList,
  ParamsType extends RootStackParamList[RouteType]
>(
  TextComponent: React.ComponentType<HTMLTextStyle>,
  LinkComponent: React.ComponentType<LinkProps<RouteType, ParamsType>>
) {
  return ({ children, style }: React.PropsWithChildren<HTMLTextStyle>) => {
    if (typeof children === "string") {
      const output: (string | JSX.Element)[] = [];
      const matches = children.matchAll(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi
      );
      let pointer = 0;
      for (const match of matches) {
        if (match.index) {
          const url = match[0];

          output.push(children.substr(pointer, match.index - pointer));
          output.push(
            <LinkComponent
              key={match.index}
              style={{ color: colors.blue }}
              href={url}
            >
              {url}
            </LinkComponent>
          );
          pointer = match.index + url.length;
        }
      }
      return <TextComponent style={style}>{output}</TextComponent>;
    } else {
      return <TextComponent style={style}>{children}</TextComponent>;
    }
  };
}

export const TextH1 = GenericText(H1, textStyles.h1);
export const TextH2 = GenericText(H2, textStyles.h2);
export const TextP = GenericText(P, textStyles.p);
export const TextTiny = GenericText(P, textStyles.tiny);

export const LinkH2 = GenericLink(textStyles.h2);
export const LinkP = GenericLink(textStyles.p);
export const LinkTiny = GenericLink(textStyles.tiny);

export const HyperTextP = GenericHyperText(TextP, LinkP);

export const LinkContainer = <
  RouteType extends keyof RootStackParamList,
  ParamsType extends RootStackParamList[RouteType]
>({
  children,
  onPress,
  navigate,
  style,
}: React.PropsWithChildren<{
  onPress?: () => void;
  navigate?: { name: RouteType; params: ParamsType };
  style?: StyleProp<ViewStyle>;
}>) => {
  const navigation = useNavigation<NavigationProp>();

  return (
    <TouchableOpacity
      onPress={() => {
        if (onPress) {
          onPress();
        }

        if (navigate) {
          navigation.navigate(navigate);
        }
      }}
      style={[style]}
    >
      {children}
    </TouchableOpacity>
  );
};

export const TextInputP = ({ style, ...props }: TextInputProps) => {
  return (
    <TextInput
      style={[
        textStyles.font,
        {
          backgroundColor: colors.white,
          color: colors.black,
          fontSize: spacing[16],
          height: spacing[32],
          paddingLeft: spacing[16],
          paddingRight: spacing[16],
        },
        style,
      ]}
      {...props}
    />
  );
};
