import React from "react";
import { TouchableOpacity } from "react-native";
import * as Analytics from "expo-firebase-analytics";
import { useDispatch } from "react-redux";
import { useDeferredLoads } from "react-loads";
import { HeartIcon } from "../components/Icons";
import spacing from "../lib/spacing";
import colors from "../lib/colors";
import { Video, createLike } from "../lib/api";
import { favoriteVideo } from "../redux/user";
import useTypedSelector from "../redux/useTypedSelector";

type Props = {
  video: Video;
};

function Like({ video }: Props) {
  const dispatch = useDispatch();
  const favoriteVideoIds = useTypedSelector(
    (state) => state.user.favoriteVideoIds
  );
  const userId = useTypedSelector((state) => state.user.id);
  const liked = favoriteVideoIds[video.id];

  const { load } = useDeferredLoads("like", createLike);

  return (
    <TouchableOpacity
      onPress={() => {
        if (!liked) {
          Analytics.logEvent("like_video", {
            content_type: "video",
            item_id: video.id,
            item_name: video.title,
            item_category: video.sectionId,
          });
          Analytics.logEvent("add_to_wishlist", {
            content_type: "video",
            item_id: video.id,
            item_name: video.title,
            item_category: video.sectionId,
          });
        } else {
          Analytics.logEvent("dislike_video", {
            content_type: "video",
            item_id: video.id,
            item_name: video.title,
            item_category: video.sectionId,
          });
        }

        load({ videoId: video.id, like: !liked, userId });

        dispatch(favoriteVideo({ videoId: video.id, like: !liked }));
      }}
      style={{ width: spacing[24], height: spacing[24] }}
    >
      <HeartIcon
        color={liked ? colors.blue : "none"}
        strokeColor={colors.blue}
      />
    </TouchableOpacity>
  );
}

export default Like;
