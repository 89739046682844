import * as React from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../App";
import { RouteProp } from "@react-navigation/native";
import Container from "../components/Container";
import { LinkH2 } from "../components/TextHelpers";
import { StyleSheet } from "react-native";
import spacing from "../lib/spacing";
import colors from "../lib/colors";
import { Types } from "../lib/reducers";
import { Nav } from "@expo/html-elements";
import { useDispatch } from "react-redux";
import { clearViewHistory } from "../redux/user";

type MenuScreenNavigationProp = StackNavigationProp<RootStackParamList, "Menu">;

type MenuScreenRouteProp = RouteProp<RootStackParamList, "Menu">;

type Props = {
  route: MenuScreenRouteProp;
  navigation: MenuScreenNavigationProp;
};

const styles = StyleSheet.create({
  link: {
    color: colors.blue,
    marginBottom: spacing[32],
  },
});

function MenuScreen({ route, navigation }: Props) {
  // const [
  //   pushNotificationsEnabled,
  //   setPushNotificationsEnabled,
  // ] = React.useState(false);

  const dispatch = useDispatch();

  return (
    <Container style={{ padding: spacing[16] }}>
      {/* <View
        style={{
          flexDirection: "row",
        }}
      >
        <TextH2 style={[styles.link, { marginRight: spacing[16] }]}>
          Turn on Notifications
        </TextH2>
        <View style={{ flex: 1, alignItems: "center" }}>
          <Switch
            onTintColor={colors.blue}
            thumbColor={colors.white}
            tintColor="#797979"
            onValueChange={(value) => {
              setPushNotificationsEnabled(value);
            }}
            value={pushNotificationsEnabled}
          />
        </View>
      </View> */}
      <Nav>
        <LinkH2
          href="/favorites"
          navigate={{ name: "Favorites", params: undefined }}
          style={styles.link}
        >
          Favorites
        </LinkH2>
        <LinkH2
          href="/featured"
          navigate={{ name: "Featured", params: undefined }}
          style={styles.link}
        >
          Featured
        </LinkH2>
        <LinkH2
          style={styles.link}
          onPress={() => {
            dispatch(clearViewHistory());
          }}
        >
          Clear Watch History
        </LinkH2>
        <LinkH2 href="https://www.bahaiblog.net/" style={styles.link}>
          Baha'i Blog Website
        </LinkH2>
        <LinkH2
          href="https://www.youtube.com/channel/UC_wNlQnJNP8LRAhjGF3ZLKA"
          style={styles.link}
        >
          Baha'i Blog YouTube Channel
        </LinkH2>
        <LinkH2
          href="mailto:editor@bahaiblog.net?subject=Feedback: Baha'i Blog TV App"
          style={styles.link}
        >
          Contact Us
        </LinkH2>
      </Nav>
    </Container>
  );
}

export default MenuScreen;
