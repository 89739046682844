import * as React from "react";
import { View, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Header } from "@expo/html-elements";
import { useDispatch } from "react-redux";
import { TextH2, LinkContainer } from "./TextHelpers";
import { enable } from "../redux/audio";
import { HamburgerMenuIcon, Logo } from "./Icons";
import { NavigationProp } from "../App";
import colors from "../lib/colors";
import spacing from "../lib/spacing";

function BBTVHeader() {
  const navigation = useNavigation<NavigationProp>();
  const dispatch = useDispatch();

  const [count, setCount] = React.useState(0);

  const onPress = React.useCallback(() => {
    setCount(count + 1);
  }, [count]);

  React.useEffect(() => {
    if (count === 19) {
      dispatch(enable());
    }
  }, [count, dispatch]);

  return (
    <Header
      style={{
        alignItems: "center",
        alignSelf: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        width: "100%",
        // maxWidth: MAX_SCREEN_WIDTH,
        paddingLeft: spacing[8],
        paddingRight: spacing[8],
      }}
    >
      <View style={{ paddingTop: spacing[8], paddingBottom: spacing[8] }}>
        <TouchableOpacity activeOpacity={1} onPress={onPress}>
          <Logo />
        </TouchableOpacity>
      </View>
      <TextH2>Baha'i Inspired Videos</TextH2>
      <LinkContainer navigate={{ name: "Menu", params: undefined }}>
        <HamburgerMenuIcon color={colors.white} />
      </LinkContainer>
    </Header>
  );
}

export default BBTVHeader;
