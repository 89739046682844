import * as React from "react";
import { AppLoading } from "expo";

import { useFonts, NewsCycle_400Regular } from "@expo-google-fonts/news-cycle";

function FontLoader({ children }: React.PropsWithChildren<{}>) {
  let [fontsLoaded] = useFonts({
    NewsCycle_400Regular,
  });

  if (fontsLoaded) {
    return children;
  } else {
    return <AppLoading />;
  }
}

export default FontLoader;
