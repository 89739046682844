import { combineReducers } from "@reduxjs/toolkit";
import user from "./user";
import search from "./search";
import video from "./video";
import section from "./section";
import audio from "./audio";

const rootReducer = combineReducers({
  user,
  search,
  video,
  section,
  audio,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
