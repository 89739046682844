import * as React from "react";
import * as Notifications from "expo-notifications";
import Constants from "expo-constants";
import * as Permissions from "expo-permissions";
import { Platform } from "react-native";
import { Subscription } from "@unimodules/core";
import { DevicePushToken } from "expo-notifications";
import { useDeferredLoads } from "react-loads";
import { createDevice } from "./api";
import * as Analytics from "expo-firebase-analytics";
import useTypedSelector from "../redux/useTypedSelector";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

async function registerForPushNotificationsAsync() {
  let expoToken = null;
  let deviceToken = null;
  const platform = Platform.OS;
  if (Constants.isDevice && !Constants.platform?.web) {
    const { status: existingStatus } = await Permissions.getAsync(
      Permissions.NOTIFICATIONS
    );
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      alert("Failed to get push token for push notification!");
      return { expoToken, deviceToken, platform };
    }
    expoToken = (await Notifications.getExpoPushTokenAsync()).data;
    deviceToken = await Notifications.getDevicePushTokenAsync();
    console.log(expoToken, deviceToken);
  } else {
  }

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  return { expoToken, deviceToken, platform };
}

async function sendPushNotification(expoPushToken: string) {
  const message = {
    to: expoPushToken,
    sound: "default",
    title: "Original Title",
    body: "And here is the body!",
    data: { data: "goes here" },
  };

  await fetch("https://exp.host/--/api/v2/push/send", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Accept-encoding": "gzip, deflate",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });
}

function PushNotificationHandler() {
  const [expoPushToken, setExpoPushToken] = React.useState<null | {
    deviceToken: DevicePushToken | null;
    expoToken: string | null;
    platform: string;
  }>(null);
  const [
    notification,
    setNotification,
  ] = React.useState<Notifications.Notification | null>(null);
  const notificationListener = React.useRef<Subscription>();
  const responseListener = React.useRef<Subscription>();

  React.useEffect(() => {
    registerForPushNotificationsAsync().then((deviceData) => {
      if (deviceData) {
        setExpoPushToken(deviceData);
      }
    });

    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener(
      (notification) => {
        setNotification(notification);
      }
    );

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        console.log(response);
      }
    );

    return () => {
      if (notificationListener.current) {
        Notifications.removeNotificationSubscription(
          notificationListener.current
        );
      }
      if (responseListener.current) {
        Notifications.removeNotificationSubscription(responseListener.current);
      }
    };
  }, []);

  const userId = useTypedSelector((state) => state.user.id);

  React.useEffect(() => {
    if (userId) {
      Analytics.setUserId(userId);
    }
  }, [userId]);

  const { load } = useDeferredLoads("device", createDevice, {
    variables: [expoPushToken],
  });

  React.useEffect(() => {
    if (userId && expoPushToken) {
      load({ ...expoPushToken, id: userId });
    }
  }, [expoPushToken, userId]);

  return null;

  // DEBUG CODE
  // return (
  //   <View
  //     style={{
  //       flex: 1,
  //       alignItems: "center",
  //       justifyContent: "space-around",
  //     }}
  //   >
  //     <Text>
  //       Your expo push token: {expoPushToken?.expoToken} ::{" "}
  //       {expoPushToken?.deviceToken.type} ::{" "}
  //       {JSON.stringify(expoPushToken?.deviceToken.data)}
  //     </Text>
  //     <View style={{ alignItems: "center", justifyContent: "center" }}>
  //       <Text>
  //         Title: {notification && notification.request.content.title}{" "}
  //       </Text>
  //       <Text>Body: {notification && notification.request.content.body}</Text>
  //       <Text>
  //         Data:{" "}
  //         {notification && JSON.stringify(notification.request.content.data)}
  //       </Text>
  //     </View>
  //     <Button
  //       title="Press to Send Notification"
  //       onPress={async () => {
  //         if (expoPushToken && expoPushToken.expoToken) {
  //           await sendPushNotification(expoPushToken.expoToken);
  //         }
  //       }}
  //     />
  //   </View>
  // );
}

export default PushNotificationHandler;
