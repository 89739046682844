export default {
  4: 4,
  8: 8,
  12: 12,
  16: 16,
  24: 24,
  32: 32,
  48: 48,
  64: 64,
  128: 128,
  256: 256,
  512: 512,
  1024: 1024,
};
