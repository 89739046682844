import {
  HomeResponse,
  SectionVideosResponse,
  SearchResponse,
  VideoResponse,
  SectionResponse,
  VideosResponse,
  Device,
  SectionsResponse,
  Like,
} from "./types";

const API_ROOT = "https://us-central1-tvapp-199501.cloudfunctions.net/api2";
// const API_ROOT = "http://192.168.1.102:5001/tvapp-199501/us-central1/api2";

export async function getHome(): Promise<HomeResponse> {
  const response = await fetch(`${API_ROOT}/home`);
  return response.json();
}

export async function getSectionVideos(
  sectionId: string
): Promise<SectionVideosResponse> {
  const response = await fetch(`${API_ROOT}/videos/${sectionId}`);
  return response.json();
}

export async function getSearch(term?: string): Promise<SearchResponse> {
  const response = await fetch(`${API_ROOT}/search/${term}`);
  return response.json();
}

export async function createDevice(device: Device): Promise<{}> {
  const response = await fetch(`${API_ROOT}/device`, {
    method: "POST",
    body: JSON.stringify(device),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.json();
}

export async function createLike(like: Like): Promise<{}> {
  const response = await fetch(`${API_ROOT}/like`, {
    method: "POST",
    body: JSON.stringify(like),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.json();
}

export async function getVideo(videoId: string): Promise<VideoResponse> {
  const response = await fetch(`${API_ROOT}/video/${videoId}`);
  return response.json();
}

export async function getSections(): Promise<SectionsResponse> {
  const response = await fetch(`${API_ROOT}/sections`);
  return response.json();
}

export enum ApiResourceKey {
  HOME = "HOME",
  VIDEO_SECTION = "VIDEO_SECTION",
  VIDEO = "VIDEO",
  SECTIONS = "SECTIONS",
}

/// ////////////

export interface Video {
  audioURL?: string | null;
  embedUrl: string;
  url: string;
  title: string;
  thumbnail: string;
  description: string;
  id: string;
  sectionId: string;
  source: "youtube" | "vimeo";
}

function getVideoThumbnail(video: VideoResponse) {
  if (video.source === "vimeo") {
    return video.vimeoData.pictures.sizes[3].link;
  }
  return video.youtubeData.snippet.thumbnails.medium.url;
}

function getVideoTitle(video: VideoResponse) {
  if (video.source === "vimeo") {
    return video.vimeoData.name;
  }
  return video.youtubeData.snippet.title;
}

function getVideoDescription(video: VideoResponse) {
  if (video.source === "vimeo") {
    return video.vimeoData.description;
  }
  return video.youtubeData.snippet.description;
}

function getVideoEmbedUrl(video: VideoResponse) {
  if (video.source === "vimeo") {
    return `https://player.vimeo.com/video/${video.vimeoId}?color=ffffff&title=0&byline=0&portrait=1`;
  }
  return `https://www.youtube.com/embed/${video.youtubeId}`;
}

function getVideoUrl(video: VideoResponse) {
  if (video.source === "vimeo") {
    return `https://vimeo.com/${video.vimeoId}`;
  }
  return `https://www.youtube.com/watch?v=${video.youtubeId}`;
}

export function parseVideo(video: VideoResponse): Video {
  return {
    id: video.id,
    title: getVideoTitle(video),
    thumbnail: getVideoThumbnail(video),
    embedUrl: getVideoEmbedUrl(video),
    url: getVideoUrl(video),
    description: getVideoDescription(video),
    sectionId: video.sectionId,
    source: video.source,
    audioURL: video.audioURL,
  };
}

export function parseVideos(videos: VideoResponse[]): Video[] {
  return videos.map(parseVideo);
}

interface SectionResponseWithVideos extends SectionResponse {
  videos: Video[];
}

export function parseSections(
  sections: SectionResponse[],
  videoSections: VideosResponse[]
) {
  const sectionsHash = sections.reduce((prev, curr) => {
    prev[curr.id] = { ...curr, videos: [] };
    return prev;
  }, {} as Record<string, SectionResponseWithVideos>);

  videoSections.forEach((videoSection) => {
    sectionsHash[videoSection.sectionId].videos = sectionsHash[
      videoSection.sectionId
    ].videos.concat(parseVideos(videoSection.videos));
  });

  return Object.keys(sectionsHash)
    .sort(function (a, b) {
      return sectionsHash[a].orderIndex - sectionsHash[b].orderIndex;
    })
    .map((sectionId) => {
      return sectionsHash[sectionId];
    });
}
