import * as React from "react";
import { RefreshControl } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useLoads } from "react-loads";
import { Section } from "@expo/html-elements";
import { useDispatch } from "react-redux";
import { RootStackParamList } from "../App";
import Container from "../components/Container";
import VideoCarousel from "../components/VideoCarousel";
import { LinkH2 } from "../components/TextHelpers";
import spacing from "../lib/spacing";
import { getHome, ApiResourceKey } from "../lib/api";
import SearchBar from "../components/SearchBar";
import selectFavoriteVideoIds from "../selectors/selectFavoriteVideoIds";
import useTypedSelector from "../redux/useTypedSelector";
import { homeResponseUpdate } from "../redux/video";

type HomeScreenNavigationProp = StackNavigationProp<RootStackParamList, "Home">;

type HomeScreenRouteProp = RouteProp<RootStackParamList, "Home">;

type Props = {
  route: HomeScreenRouteProp;
  navigation: HomeScreenNavigationProp;
};

function HomeScreen({ navigation }: Props) {
  const dispatch = useDispatch();
  const { response, isPending, load } = useLoads(ApiResourceKey.HOME, getHome);

  React.useEffect(() => {
    if (response) {
      dispatch(homeResponseUpdate(response));
    }
  }, [dispatch, response]);

  const audioVideoIds = useTypedSelector((state) => state.audio.videoIds);
  const favoriteVideoIds = useTypedSelector(selectFavoriteVideoIds);
  const featured = useTypedSelector((state) => state.video.featured);
  const featuredVideosBySection = useTypedSelector(
    (state) => state.video.featuredVideosBySection
  );
  const sections = useTypedSelector((state) => state.section.sections);
  const sectionData = useTypedSelector((state) => state.section.sectionData);

  return (
    <Container
      refreshControl={
        <RefreshControl
          refreshing={isPending}
          onRefresh={load}
          title="Loading..."
        />
      }
    >
      <Section style={{ paddingTop: spacing[32] }}>
        <VideoCarousel
          size="large"
          videoIds={featured}
          limit={10}
          seeMoreOnClick={() => {
            navigation.navigate("Featured");
          }}
          itemAlignment="start"
        />
      </Section>

      <SearchBar
        onSearch={(term) => {
          navigation.navigate("Search", { term });
        }}
      />

      {audioVideoIds.length > 0 && (
        <Section style={{ paddingBottom: spacing[48] }}>
          <LinkH2
            href="/playlist"
            navigate={{ name: "Playlist", params: undefined }}
            style={{
              marginBottom: spacing[16],
              marginLeft: spacing[16],
            }}
          >
            Playlist
          </LinkH2>
          <VideoCarousel
            itemAlignment="start"
            size="small"
            videoIds={audioVideoIds}
            limit={5}
            seeMoreOnClick={() => {
              navigation.navigate("Playlist");
            }}
          />
        </Section>
      )}

      {favoriteVideoIds.length > 0 && (
        <Section style={{ paddingBottom: spacing[48] }}>
          <LinkH2
            href="/favorites"
            navigate={{ name: "Favorites", params: undefined }}
            style={{
              marginBottom: spacing[16],
              marginLeft: spacing[16],
            }}
          >
            Favorites
          </LinkH2>
          <VideoCarousel
            itemAlignment="start"
            size="small"
            videoIds={favoriteVideoIds}
            limit={5}
            seeMoreOnClick={() => {
              navigation.navigate("Favorites");
            }}
          />
        </Section>
      )}

      {sections.map((sectionId) => {
        const section = sectionData[sectionId];

        if (section) {
          return (
            <Section key={section.id} style={{ paddingBottom: spacing[48] }}>
              <LinkH2
                href={`/category/${section.id}`}
                navigate={{
                  name: "Section",
                  params: { sectionId: section.id },
                }}
                style={{
                  marginBottom: spacing[16],
                  marginLeft: spacing[16],
                }}
              >
                {section.category}
              </LinkH2>
              <VideoCarousel
                itemAlignment="start"
                size="small"
                videoIds={featuredVideosBySection[section.id] || []}
                seeMoreOnClick={() => {
                  navigation.navigate("Section", { sectionId: section.id });
                }}
              />
            </Section>
          );
        }
        return null;
      })}
    </Container>
  );
}

export default HomeScreen;
