import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AudioState = {
  enabled: boolean;
  videoIds: string[];
  index: number;
};

const initialState: AudioState = {
  enabled: false,
  videoIds: [],
  index: 0,
};

const searchSlice = createSlice({
  name: "audio",
  initialState,
  reducers: {
    queue(state, action: PayloadAction<{ videoId: string }>) {
      state.videoIds.push(action.payload.videoId);
    },
    clear(state) {
      state.videoIds = [];
      state.index = 0;
    },
    next(state) {
      const newIndex = state.index + 1;
      if (newIndex >= state.videoIds.length) {
        state.index = 0;
      } else {
        state.index = newIndex;
      }
    },
    previous(state) {
      const newIndex = state.index - 1;
      if (newIndex < 0) {
        state.index = state.videoIds.length - 1;
      } else {
        state.index = newIndex;
      }
    },
    enable(state) {
      state.enabled = !state.enabled;
    },
  },
});

export const { queue, clear, next, previous, enable } = searchSlice.actions;

export default searchSlice.reducer;
