import * as React from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../App";
import { RouteProp } from "@react-navigation/native";
import Container from "../components/Container";
import VideoGrid from "../components/VideoGrid";
import { TextP } from "../components/TextHelpers";
import selectFavoriteVideoIds from "../selectors/selectFavoriteVideoIds";
import spacing from "../lib/spacing";
import colors from "../lib/colors";
import useTypedSelector from "../redux/useTypedSelector";

type FavoritesScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Favorites"
>;

type FavoritesScreenRouteProp = RouteProp<RootStackParamList, "Favorites">;

type Props = {
  route: FavoritesScreenRouteProp;
  navigation: FavoritesScreenNavigationProp;
};

function FavoritesScreen({ route, navigation }: Props) {
  const favoriteVideoIds = useTypedSelector(selectFavoriteVideoIds);

  return (
    <Container style={{ padding: spacing[16] }}>
      {favoriteVideoIds.length <= 0 ? (
        <TextP style={{ color: colors.grey }}>
          Looks like you don't have any favorites yet. To add to favorites,
          click the heart under a video.
        </TextP>
      ) : (
        <VideoGrid videoIds={favoriteVideoIds} />
      )}
    </Container>
  );
}

export default FavoritesScreen;
