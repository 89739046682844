import { RootState } from "../redux/rootReducer";

function selectFavoriteVideoIds(state: RootState) {
  const favoriteVideoIds = state.user.favoriteVideoIds;
  return Object.keys(favoriteVideoIds).filter(
    (videoId) => favoriteVideoIds[videoId]
  );
}

export default selectFavoriteVideoIds;
