import * as React from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp } from "@react-navigation/native";
import { WebView } from "react-native-webview";
import { Platform, View, Dimensions } from "react-native";
import * as Analytics from "expo-firebase-analytics";
import { useDispatch } from "react-redux";
import { useLoads } from "react-loads";
import { RootStackParamList } from "../App";
import Container from "../components/Container";
import Like from "../components/Like";
import { TextH1, LinkP, LinkH2, HyperTextP } from "../components/TextHelpers";
import spacing from "../lib/spacing";
import colors from "../lib/colors";
import { viewVideoPage } from "../redux/user";
import useTypedSelector from "../redux/useTypedSelector";
import { ApiResourceKey, getVideo, getSections } from "../lib/api";
import { videoResponseUpdate } from "../redux/video";
import { getSectionsResponse } from "../redux/section";
import { queue } from "../redux/audio";

type VideoScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Video"
>;

type VideoScreenRouteProp = RouteProp<RootStackParamList, "Video">;

type Props = {
  route: VideoScreenRouteProp;
  navigation: VideoScreenNavigationProp;
};

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

function VideoScreen({ route, navigation }: Props) {
  const dispatch = useDispatch();
  const videoData = useTypedSelector((state) => state.video.videoData);
  const sectionData = useTypedSelector((state) => state.section.sectionData);
  const audioEnabled = useTypedSelector((state) => state.audio.enabled);

  const { videoId } = route.params;

  const video = videoData[videoId];

  const { response: sectionsResponse } = useLoads(
    ApiResourceKey.SECTIONS,
    getSections
  );

  const { response } = useLoads(ApiResourceKey.VIDEO, getVideo, {
    variables: [videoId],
  });

  if (video) {
    navigation.setOptions({ title: video.title });
  }

  React.useEffect(() => {
    if (response && sectionsResponse) {
      dispatch(videoResponseUpdate(response));
      dispatch(getSectionsResponse(sectionsResponse));
    }
  }, [response, sectionsResponse, dispatch]);

  React.useEffect(() => {
    if (video) {
      Analytics.logEvent("view_item", {
        item_id: videoId,
        item_name: video.title,
        item_category: video.sectionId,
      });

      dispatch(viewVideoPage({ videoId }));
    }
  }, [videoId, video, dispatch]);

  if (!video) {
    return null;
  }

  const section = sectionData[video.sectionId];

  if (!section) {
    return null;
  }

  async function playSound() {
    if (audioEnabled && video.audioURL) {
      dispatch(queue({ videoId: video.id }));
    }
  }

  return (
    <Container>
      <TextH1 style={{ margin: spacing[16] }}>{video.title}</TextH1>
      <LinkH2
        href={`/category/${section.id}`}
        navigate={{ name: "Section", params: { sectionId: section.id } }}
        style={{
          padding: spacing[16],
          color: colors.blue,
          textTransform: "uppercase",
        }}
      >
        {section.category}
      </LinkH2>
      {Platform.OS === "web" ? (
        <iframe
          src={video.embedUrl}
          style={{
            width: "100%",
            height: "calc((9 / 16) * 100vw)",
            maxHeight: "calc(100vh - 169px)",
            border: 0,
            alignSelf: "center",
          }}
        />
      ) : (
        <WebView
          source={{ uri: video.embedUrl }}
          allowsFullscreenVideo
          userAgent="Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.90 Safari/537.36"
          style={{
            width: "100%",
            height: (9 / 16) * windowWidth,
            maxHeight: windowHeight - 169,
          }}
        />
      )}
      <View
        style={{
          padding: spacing[16],
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <LinkP href={video.url} style={{ color: colors.blue }}>
            View on {video.source === "youtube" ? "YouTube" : "Vimeo"}
          </LinkP>
          {audioEnabled && video.audioURL && (
            <LinkP onPress={playSound} style={{ color: colors.blue }}>
              {" | Add to playlist"}
            </LinkP>
          )}
        </View>
        <Like video={video} />
      </View>

      <View style={{ padding: spacing[16] }}>
        <HyperTextP style={{ color: colors.grey }}>
          {video.description}
        </HyperTextP>
      </View>
    </Container>
  );
}

export default VideoScreen;
